<template>
  <div>
    <ProductGrid
      v-for="proposal in proposals"
      :key="proposal.id"
      :proposal="proposal"
      :title="title ? title : proposal.descr"
      :paginationClass="paginationClass + proposal.id"
      :cols="cols"
      :sm="sm"
      :md="md"
      :lg="lg"
    >
    </ProductGrid>
  </div>
</template>
<script>
import ProductGrid from "@/components/product/ProductGrid.vue";

export default {
  name: "ProposalProductGrid",
  components: {
    ProductGrid
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    proposals: {
      type: Array,
      required: true
    },
    paginationClass: {
      type: String,
      required: false,
      default: "swiper-pagination"
    },
    cols: { type: Number, default: 1 },
    sm: { type: Number, default: 2 },
    md: { type: Number, default: 3 },
    lg: { type: Number, default: 4 }
  }
};
</script>
